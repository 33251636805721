var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mark-complete-container",
      class: { disabled: !_vm.column.editable },
    },
    [
      _c(
        "el-checkbox",
        {
          class: _vm.column.isComplete ? "complete" : "",
          style: _vm.bindStyle,
          attrs: {
            "true-label": 0,
            "false-label": 1,
            disabled: !_vm.column.editable,
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.column.columnValue,
            callback: function ($$v) {
              _vm.$set(_vm.column, "columnValue", $$v)
            },
            expression: "column.columnValue",
          },
        },
        [_vm._v(_vm._s(_vm.targetFieldName))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }