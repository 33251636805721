<!--
 * @Author: pengyu
 * @Date: 2020-07-22 16:26:57
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-16 11:31:34
 * @Description: 标记完成控件
-->
<template>
  <div class="mark-complete-container" :class="{'disabled': !column.editable}">
    <el-checkbox
      v-model="column.columnValue"
      :style="bindStyle"
      :true-label="0"
      :false-label="1"
      :disabled="!column.editable"
      @change="handleChange"
      :class="column.isComplete ? 'complete' : ''"
    >{{targetFieldName}}</el-checkbox>
  </div>
</template>

<script>
import { AssignmentOptions, BusinessFieldType, BusinessDataOpenType } from "../../../data/BusinessCommonHeader.js";
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMMarkCompleteColumn",
  mixins: [MxColumnData],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    targetFieldName() {
      const { targetField } = this.column;
      const target = this.column.findMainAreaFieldByUuid(targetField);
      return target?.name || "";
    }
  },
  mounted() {
    if (this.businessData.openType !== BusinessDataOpenType.add) {
      // 非新增的时候  需要触发一下 值改变
      this.handleChange(this.column.columnValue);
    }
  },
  methods: {
    /**
     * @description: 复选框选择事件
     * @param value
     * @return:
     */
    handleChange(value) {
      // 找到 被标记字段 改变其状态
      const {
        targetField, uuid, relatedPlanFlag, mainAreaFields
      } = this.column;
      if (targetField === uuid) { // 选择字段字段为自己
        this.column.isComplete = value === 0;
      } else {
        const tf = this.column.findMainAreaFieldByUuid(targetField);
        tf.isComplete = value === 0;
      }
      if (relatedPlanFlag === 0) { // 关联了进度
        const jindu = mainAreaFields.filter((itm) => itm.fieldType === BusinessFieldType.newProgress); // 找出当前模板的进度字段
        if (jindu[0]) { // 配置了进度字段
          const { columnValue } = jindu[0]; // 百分比值
          jindu[0].showValue = value === 0 ? 100 : columnValue?.value;
          // 触发进度字段的值改变
          jindu[0].updateValueWithOptions(jindu[0].showValue, AssignmentOptions.change);
        }
      }

      this.column.updateValueWithOptions(this.column.columnValue, AssignmentOptions.manual);
    }
  }
};
</script>

<style lang='scss' scoped>
.mark-complete-container{
  width: 100%;
  border: 1px solid $field-border-color;
  border-radius: 4px;
  height: 32px;
  min-height: 32px;
  text-align: left;
  padding: 0 12px;
  .el-checkbox{
    width: 100%;
  }
  & /deep/ .el-checkbox__label{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  &:hover{
    border-color: $field-border-color-hover;
  }
  .complete{
    & /deep/ .el-checkbox__label{
      text-decoration: line-through;
    }
  }
  &.disabled{
    background: #f5f7fa;
    border: none;
  }
}
</style>
